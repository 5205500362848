<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">REQUEST SYSTEM</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" onClick="window.location.reload();">
                      <i class="fa fa-minus-square"></i>
                      Refresh
                  </button>
                  <button type="button" class="btn btn-sm btn-secondary" onclick="history.back()">
                      <i class="fa fa-minus-square"></i>
                      Back
                  </button>
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <!-- e  <datatable :columns="columns" :data="connections"></datatable>
                  <datatable-pager v-model="page" type="abbreviated" :per-page="per_page"></datatable-pager>
    -->
    <br>

                <table class="table table-hover" id='datatable'>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Name</th>
                      <th>Title</th>
                      <th>Type</th>
                      <th style="width: 12%;">Status</th>
                      <th>Assigned user</th>
                      <th>Last Updated</th>
                      <th>Date Created</th>
                      <th  style="width: 8%;">Priority</th>
                      <th>Actions</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="c in connections" :key="c.id">
                      <td>{{c.useremail}}</td>
                      <td>{{c.username}}</td>
                      <td>{{c.title}}</td>
                      <td>{{c.type}}</td>
                      <!-- <td><img v-bind:src="'/' + product.photo" width="100" alt="product"></td> -->
                       <td v-if="c.status=='Request Submitted'"><span class="circle blue"> Submitted </span></td>
                      <td v-if="c.status=='Request In Progress'"><span class="circle lo"> In progress</span></td>
                      <td v-if="c.status=='Request Requires Further Information'"><span class="circle br"> Further Info </span></td>
                      <td v-if="c.status=='Request Complete'" ><span class="circle green"> Complete </span></td>

                      <td>{{c.in_charge_user_name}}</td>
                      <td>{{datetime(c.updated_at)}}</td>
                      <td>{{datetime(c.created_at)}}</td>
                       <td v-if="c.priority=='Low'"><span class="circle lb"> {{c.priority}} </span></td>
                      <td v-if="c.priority=='High'"><span class="circle lo"> {{c.priority}} </span></td>
                      <td v-if="c.priority=='Medium'"><span class="circle lr"> {{c.priority}} </span></td>
                      <td v-if="c.priority=='Urgent'"><span class="circle br"> {{c.priority}} </span></td>
                      <td v-if="c.priority==NULL"></td>

                      <td>
                        
                        <!-- <a href="#" @click="editModal(c)">
                            <i class="fa fa-edit blue"></i>
                        </a>  /-->
                       
                        <a href="#" @click="deleteConnection(c.id)">
                            <i class="fa fa-trash red"></i>Delete
                        </a>
                       |<a :href="'/adminview/'+c.id+'?username='+username+'&useremail='+useremail">
                            <i class="fa fa-eye blue"></i> View Request
                        </a> 
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Request</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Product</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateProduct() : createConnection()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="form-group">  
                          <label for="exampleSelectRounded0">Request Type</label>
                          <select v-model="form.type" class="custom-select rounded-0" id="exampleSelectRounded0">
                            <option>Basic Setup</option>
                            <option>Website Build</option>
                            <option>Funnel Build</option>
                            <option>Email Template Design</option>
                            <option>Social Media Management</option>
                            <option>Technical Support Done-for you</option>
                            <option>Training for you or your staff</option>
                          </select>
                
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>TITLE</label>
                            <input v-model="form.title" type="text" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                            <has-error :form="form" field="description"></has-error>
                        </div>
                        <div class="form-group">
                            
                          <label>DESCRIPTION</label>
                          <textarea class="form-control" v-model="form.description" rows="3" placeholder="Describe your request"></textarea>
                        
                            <has-error :form="form" field="price"></has-error>
                        </div>
                         <div class="form-group">
                                    
                            <label for="exampleInputFile">Attach A file</label>
                            <div class="input-group">
                              <div class="custom-file">
                                <input type="file" class="custom-file-input" id="exampleInputFile" @change="onChange" accept="image/*">
                                <label class="custom-file-label" for="exampleInputFile" id="filelabel">Choose file</label>
                              </div>
                           
                            </div>
                  
                            <has-error :form="form" field="price"></has-error>
                        </div>
                          <div class="form-group">
                            <label>EXACT texte you would like on your design  </label>
                            <input v-model="form.text" type="text" name="price"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                            <has-error :form="form" field="price"></has-error>
                        </div>
                   
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create Request</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import moment from "moment"
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import $ from "jquery";

    export default {
      components: {
          VueTagsInput,
          moment
          },
        data () {
            return {
                editmode: false,
              //  url_data:'',
           //     file:'',
                connections : {},
                form: new Form({
                    type : '',
                    description : '',
                    text: '',
                    username:'',
                    useremail:'',
                    locationid:'',
                    locationname:'',
                    title: '',
                    file:'',
                }),
                username:'',
                useremail:'',
                categories: [],
              
                tag:  '',
                autocompleteItems: [],
                columns: [
                        {label: 'Email', field: 'useremail'}, 
                        {label: 'Name', field: 'username'}, 
                        {label: 'Title', field: 'title'},
                        {label: 'Type', field: 'type'},
                        {label: 'Status', field: 'status'},
                        {label: 'User', field: 'in_charge_user_name'},
                        {label: 'Updated', field: 'updated_at'},
                        {label: 'Created', field: 'created_at'},
                        {label: 'Priority', field: 'priority'}
                   //     {label: 'Actions', field: 'Actions'}
                    ],
                rows: [],
                page: 1,
                per_page: 10,
            }
        },
        methods: {
          onChange(e) {
           // console.log(url_data)
                this.createbase64image(e.target.files[0])
               // console.log(e.target.files[0]);
                document.getElementById('filelabel').innerText=e.target.files[0].name;
            },

          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/product?page=' + page).then(({ data }) => (this.connections = data.data));

              this.$Progress.finish();
          },
          createbase64image(fileObject){
            const reader = new FileReader();
            reader.onload = (e) => {
              this.form.file = e.target.result
            };
            reader.readAsDataURL(fileObject);
          },
          loadConnections(){
              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
            const useremail = urlParams.get('useremail');

            // if(this.$gate.isAdmin()){
              axios.get(`/api/adminconnections?email=${useremail}`).then(({ data }) => (this.connections = data.data))
              .then((data) => {
              setTimeout(() => {
                $("#datatable").DataTable({
                  lengthMenu: [
                    [5,10, 25, 50, -1],
                    [5,10, 25, 50, "All"],
                  ],
                  pageLength: 10,
                });
              });
            });
            // }
          },
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
          loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id };
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createConnection(){
              this.$Progress.start();
              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
              this.form.username = urlParams.get('username');
              this.form.useremail = urlParams.get('useremail');
              this.form.locationid = urlParams.get('locationid');
               this.form.locationname = urlParams.get('locationname');

              this.form.post('/api/addconnection'
              )
              .then((data)=>{
                console.log(data.data.success);
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadConnections();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          
          datetime(mydate){
       return moment(mydate).format('MMMM Do YYYY, h:mm a'); 

          },
          deleteConnection(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/connection/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadConnections();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            this.form.username=this.$route.params.username;
            this.form.useremail=this.$route.params.useremail;
            this.form.locationname=this.$route.params.locationname;
            this.form.locationid=this.$route.params.locationid;
             const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
             this.useremail = urlParams.get('useremail');
             this.username= urlParams.get('username');
        },
        created() {
            this.$Progress.start();

            this.loadConnections();
            //this.loadCategories();
           // this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          }
        },
    }
</script>

<style scoped>
.image{
  width: 300px;
  height: 250px;
}
.circle{
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 5px;
}
.lb{
  background-color: lightskyblue;
}
.lo{
  background-color: orange;
}
.lr{
  background-color: lightcoral;
}
.br{
  background-color: red;
}
.green{
  background-color: lightgreen;
}
.blue{
  background-color: lightskyblue;
}
</style>
