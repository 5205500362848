<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <!-- left column -->
          <div class="col-md-12">
            <!-- general form elements -->
            
            <!-- /.card -->

            <!-- general form elements -->
            
            <!-- /.card -->

            <!-- Input addon -->
            <div class="card card-warning card-outline">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-edit"></i>
                  {{request.title}}
                </h3>
              </div>
              <div class="card-body">
                <button v-if="request.closed==1" type="button" class="btn btn-success toastrDefaultSuccess" @click="changestate">
                  <b> Request Amendments</b>
                </button>
                 <div class="text-muted mt-3">
                 <b> <i>Cat :</i></b>{{request.type}}  | <b><i>Type :</i></b> <b>{{request.subtype}}</b> 
                </div>
                 <div class="text-muted mt-3">
                 <b><i>Size:</i></b> {{request.size}}
                </div>
                <div class="text-muted mt-3">
                 <b><i>Page Url:</i></b> {{request.page}}
                </div>
                 <div class="text-muted mt-3">
                 {{request.description}}
                </div>
                <div class="text-muted mt-3">
                 <b><i>Extra pics Links:</i></b> {{request.piclink}}
                </div>
                <div class="text-muted mt-3">
                    <p><b><i>What you want done Exactly</i></b></p>
                 {{request.text}}
                </div>
                <div class="text-muted mt-3">
                <a v-if="request.image" :href="'/Pictures/'+request.image" download>File1</a>
                <a v-if="request.file2" :href="'/Pictures/'+request.file2" download>|File2</a>
                <a v-if="request.file3" :href="'/Pictures/'+request.file3" download>|File3</a>
                <a v-if="request.file4" :href="'/Pictures/'+request.file4" download>|File4</a>
                <a v-if="request.file5" :href="'/Pictures/'+request.file5" download>|File5</a>
                </div>
                <div class="text-muted mt-3">
                    <p><b><i>Date :</i></b>{{datetime(request.date)}} </p>
                 
                </div>

                <hr>

                <form @submit.prevent="submitcomment()" v-if="request.closed==0 || showform==1">
               <div class="form-group">
                        <label>Add a comment</label>
                        <textarea v-model="form.content" class="form-control" rows="3" placeholder="Enter ..."></textarea>
               </div>

               <button type="submit" class="btn btn-info btn-lg" style="color:white;">Comment</button><br>

             
            

                </form>
                <hr>
                  <div class="row">
                    <div class="timeline timeline-inverse">
                      <!-- timeline time label -->
                     
                      <!-- /.timeline-label -->
                      <!-- timeline item -->
                      <div v-for="c in comments" :key="c.id">
                        <i class="fas fa-envelope bg-success" v-if="c.type==1"></i>
                        <i class="fas fa-envelope bg-primary" v-if="c.type!=1"></i>

                        <div class="timeline-item">
                          <span class="time"><i class="far fa-clock"></i> {{datetime(c.created_at)}}</span>

                          <h3 class="timeline-header"><a href="#">{{request.username}}</a> {{request.useremail}}</h3>

                          <div class="timeline-body">
                            {{c.content}}
                          </div>
                          <div class="timeline-footer">
                            <p>Date :{{datetime(c.created_at)}} </p>
                          </div>
                        </div>
                      </div>
 
                    <div v-if="request.status=='Request Complete'">
                        <i class="fas fa-envelope bg-success"></i>

                        <div class="timeline-item">
                          <span class="time"><i class="far fa-clock"></i> {{datetime(request.created_at)}}</span>

                          <h3 class="timeline-header alert alert-success">SUPPORT'S UPDATE - <b class="">COMPLETED</b> </h3>

                          <div class="timeline-body">
                            {{request.complete_text}}
                          </div>
                          <div class="timeline-body">
                           <a :href=request.complete_link>{{request.complete_link}}</a> 
                          </div>
                          <div class="timeline-footer">
                            <p>Date :{{datetime(request.created_at)}} </p>
                          </div>
                        </div>
                      </div>

                        <div v-if="request.status=='Request Requires Further Information'">
                        <i class="fas fa-envelope bg-warning"></i>

                        <div class="timeline-item">
                          <span class="time"><i class="far fa-clock"></i> {{datetime(request.created_at)}}</span>

                          <h3 class="timeline-header alert alert-warning">SUPPORT'S UPDATE - <b> MORE INFO NEEDED</b> </h3>

                          <div class="timeline-body">
                            {{request.complete_text}}
                          </div>
                          <div class="timeline-footer">
                            <p>Date :{{datetime(request.created_at)}} </p>
                          </div>
                        </div>
                      </div>
                    
                      <div>
                        <i class="far fa-clock bg-gray"></i>
                      </div>
                    </div>

                    
                
                  </div>
            
              </div>
              <!-- /.card -->
            </div>
            <!-- /.card -->
            <!-- Horizontal Form -->
            
            <!-- /.card -->

          </div>
          <!--/.col (left) -->
          <!-- right column -->
          
          <!--/.col (right) -->
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Request</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Product</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateProduct() : createConnection()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="form-group">  
                          <label for="exampleSelectRounded0">Request Type</label>
                          <select v-model="form.type" class="custom-select rounded-0" id="exampleSelectRounded0">
                            <option>Website Update</option>
                            <option>Graphic Design</option>
                            <option>Social Media</option>
                          </select>
                
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>TITLE</label>
                            <input v-model="form.title" type="text" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                            <has-error :form="form" field="description"></has-error>
                        </div>
                        <div class="form-group">
                            
                          <label>DESCRIPTION</label>
                          <textarea class="form-control" v-model="form.description" rows="3" placeholder="Describe your request"></textarea>
                        
                            <has-error :form="form" field="price"></has-error>
                        </div>
                         <div class="form-group">
                                    
                            <label for="exampleInputFile">Attach A file</label>
                            <div class="input-group">
                              <div class="custom-file">
                                <input type="file" class="custom-file-input" id="exampleInputFile" @change="onChange" accept="image/*">
                                <label class="custom-file-label" for="exampleInputFile" id="filelabel">Choose file</label>
                              </div>
                           
                            </div>
                  
                            <has-error :form="form" field="price"></has-error>
                        </div>
                          <div class="form-group">
                            <label>EXACT texte you would like on your design  </label>
                            <input v-model="form.text" type="text" name="price"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                            <has-error :form="form" field="price"></has-error>
                        </div>
                   
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create Request</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import moment from "moment"

    export default {
      components: {
          VueTagsInput,
          moment
        },
        data () {
            return {
                editmode: false,
                showform:0,
              //  url_data:'',
           //     file:'',
                request : {},
                form: new Form({
                    setting_id : this.url_data,
                    content : '',
                }),
                comments: [],
              
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {
          onChange(e) {
           // console.log(url_data)
                this.createbase64image(e.target.files[0])
               // console.log(e.target.files[0]);
                document.getElementById('filelabel').innerText=e.target.files[0].name;
            },

         
          loadConnections(){
              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
            const useremail = urlParams.get('useremail');

            // if(this.$gate.isAdmin()){
              axios.get(`/api/connections?email=${useremail}`).then(({ data }) => (this.connections = data.data));
            // }
          },
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
       
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createConnection(){
              this.$Progress.start();
              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
              this.form.username = urlParams.get('username');
              this.form.useremail = urlParams.get('useremail');
              this.form.locationid = urlParams.get('locationid');
               this.form.locationname = urlParams.get('locationname');

              this.form.post('/api/addconnection'
              )
              .then((data)=>{
                console.log(data.data.success);
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadConnections();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          
          datetime(mydate){
       return moment(mydate).format('MMMM Do YYYY, h:mm:ss a'); 

          },
          submitcomment(){
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const username = urlParams.get('username');
              this.$Progress.start();
              this.form.post(`/api/comments/add?id=${this.url_data}&origin=${username}`)
              .then((response) => {
                  this.comments=response.data;
                
                
                  this.$Progress.finish();
                  this.form.content='';
                  
              })
              .catch(() => {
                  this.$Progress.fail();
              });


          },
          changestate(){
            this.showform=1;
            axios.get(`/api/amendments?id=${this.$route.params.id}`)
              .then((response) => {
                //  this.comments=response.data;
                
                
                  this.$Progress.finish();
                  
              })
              .catch(() => {
                  this.$Progress.fail();
              });
              Toast.fire({
                    icon: 'success',
                    title: 'You can add a comment'
                  });
          },
          deleteConnection(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/connection/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadConnections();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            this.url_data=this.$route.params.id;
            axios.get(`/api/singlerequest?id=${this.url_data}`).then(({ data }) => {this.request = data
            console.log(this.request)
            });
           // this.$Progress.start();

         var ids= this.$route.params.id
          // console.log(ids)
      
              axios.get(`/api/getcomments?id=${ids}`)
              .then((response) => {this.comments=response.data})
       

          
        },
        created() {
            this.$Progress.start();

            this.loadConnections();
            //this.loadCategories();
           // this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          }
        },
    }
</script>

<style scoped>
.image{
  width: 300px;
  height: 250px;
}
</style>
