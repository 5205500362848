<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        






            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Submit a request     
                      
</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New Request
                  </button>
                  <button type="button" class="btn btn-sm btn-primary" onClick="window.location.reload();">
                      <i class="fa fa-minus-square"></i>
                      Refresh
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover" id="datatable">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Last Updated</th>
                      <th>Date Created</th>
                      <th>Actions</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="c in connections" :key="c.id">

                      <td>{{c.title}}</td>
                      <td>{{c.type}}</td>
                      <!-- <td><img v-bind:src="'/' + product.photo" width="100" alt="product"></td> -->
                           <td v-if="c.status=='Request Submitted'"><span class="circle blue"> Submitted </span></td>
                      <td v-if="c.status=='Request In Progress'"><span class="circle lo"> In progress</span></td>
                      <td v-if="c.status=='Request Requires Further Information'"><span class="circle br"> Further Info </span></td>
                      <td v-if="c.status=='Request Complete'" ><span class="circle green"> Complete </span></td>

                      <td>{{datetime(c.updated_at)}}</td>
                      <td>{{datetime(c.created_at)}}</td>
                      <td>
                        
                        <!-- <a href="#" @click="editModal(c)">
                            <i class="fa fa-edit blue"></i>
                        </a>  /-->
                       
                        <a href="#" @click="deleteConnection(c.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                       <a :href="'/view/'+c.id+'?username='+username+'&useremail='+useremail">
                            <i class="fa fa-eye blue"></i> View Request
                        </a> 
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Request</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Product</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateProduct() : createConnection()"  enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="form-group">  
                          <p>For website update requests please click <a target="_blank" href="https://childcaremarketing.co.uk/websitesupport">here</a></p>
                          <label for="exampleSelectRounded0">Request Category</label>
                          <select v-model="form.type" class="custom-select rounded-0" id="exampleSelectRounded0" @change='setfields'>
                            <option disabled>Select and option</option>
                            <!-- <option>Website Update</option> -->
                            <option>Basic Setup</option>
                            <option>Website Build</option>
                            <option>Funnel Build</option>
                            <option>Email Template Design</option>
                            <option>Social Media Management</option>
                            <option>Technical Support Done-for you</option>
                            <option>Training for you or your staff</option>
                          </select>
                
                            <has-error :form="form" field="name"></has-error>
                        </div>

                         <div class="form-group" v-if="set>0">  
                          <label for="exampleSelectRounded0">Request Type</label>
                          <select  v-model="form.subtype" class="custom-select rounded-0" id="exampleSelectRounded0">
                           <option disabled>Select and option</option>
                            <option v-for="o in options" :key=o.id>{{o.name}}</option>
                           
                          </select>
                
                            <has-error :form="form" field="name"></has-error>
                        </div>

                        <div class="form-group">
                            <label>TITLE</label>
                            <input v-model="form.title" type="text" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                            <has-error :form="form" field="description"></has-error>
                        </div>

                         <div class="form-group" v-if="set==1">
                            <label>Page URL</label>
                            <input v-model="form.page" type="text" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                            <has-error :form="form" field="description"></has-error>
                        </div>
                         <div class="form-group" v-if="set==2">
                            <label>What size are you looking for? (E.g A4,A5 , 2X2M)</label>
                            <input v-model="form.size" type="text" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                            <has-error :form="form" field="description"></has-error>
                        </div>
                        <div class="form-group">
                            
                          <label>DESCRIPTION</label>
                          <textarea class="form-control" v-model="form.description" rows="3" placeholder="Describe your request"></textarea>
                        
                            <has-error :form="form" field="price"></has-error>
                        </div>

                        <div class="form-group">
                            
                          <label>Exact text you would like on design</label>
                          <textarea class="form-control" v-model="form.text" rows="3" placeholder="Exact text you would like on design"></textarea>
                        
                            <has-error :form="form" field="price"></has-error>
                        </div>
                      
                        
                           <div class="form-group">
                                    
                            <label for="exampleInputFile">Attach A file</label>
                            <div class="input-group">
                              <div class="custom-file">
                                <input type="file" class="custom-file-input" id="exampleInputFile" @change="onChange">
                                <label class="custom-file-label" for="exampleInputFile" id="filelabel">Choose file</label>
                              </div>
                           
                            </div>
                  
                            <has-error :form="form" field="price"></has-error>
                        </div>

                           <div class="form-group">
                                    
                            <label for="exampleInputFile">Attach A file</label>
                            <div class="input-group">
                              <div class="custom-file">
                                <input type="file" class="custom-file-input" id="exampleInputFile2" @change="onChange2">
                                <label class="custom-file-label" for="exampleInputFile2" id="filelabel2">Choose file</label>
                              </div>
                           
                            </div>
                  
                            <has-error :form="form" field="price"></has-error>
                        </div>
                           <div class="form-group">
                                    
                            <label for="exampleInputFile">Attach A file</label>
                            <div class="input-group">
                              <div class="custom-file">
                                <input type="file" class="custom-file-input" id="exampleInputFile3" @change="onChange3">
                                <label class="custom-file-label" for="exampleInputFile3" id="filelabel3">Choose file</label>
                              </div>
                           
                            </div>
                  
                            <has-error :form="form" field="price"></has-error>
                        </div>
                           <div class="form-group">
                                    
                            <label for="exampleInputFile">Attach A file</label>
                            <div class="input-group">
                              <div class="custom-file">
                                <input type="file" class="custom-file-input" id="exampleInputFile4" @change="onChange4">
                                <label class="custom-file-label" for="exampleInputFile4" id="filelabel4">Choose file</label>
                              </div>
                           
                            </div>
                  
                            <has-error :form="form" field="price"></has-error>
                        </div>
                           <div class="form-group">
                                    
                            <label for="exampleInputFile">Attach A file</label>
                            <div class="input-group">
                              <div class="custom-file">
                                <input type="file" class="custom-file-input" id="exampleInputFile5" @change="onChange5">
                                <label class="custom-file-label" for="exampleInputFile5" id="filelabel5">Choose file</label>
                              </div>
                           
                            </div>
                  
                            <has-error :form="form" field="price"></has-error>
                        </div>

                          <div class="form-group">
                            <label>More Pictures? add the link here</label>
                            <input v-model="form.piclink" type="text" placeholder="picture link e.g google drive "
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                        </div>
                   
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create Request</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import moment from "moment"
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import $ from "jquery";

    export default {
      components: {
          VueTagsInput,
          moment
        },
        data () {
            return {
                editmode: false,
              //  url_data:'',
           //     file:'',
                connections : {},
                form: new Form({
                    type : '',
                    subtype:'',
                    description : '',
                    text: '',
                    size:'',
                    page:'',
                    username:'',
                    useremail:'',
                    locationid:'',
                    locationname:'',
                    title: '',
                    file:[],
                    piclink:''
                }),
                username:'',
                useremail:'',
                categories: [],
                file1:'',
                file2:'',
                file3:'',
                file4:'',
                file5:'',
                options:[],
                set:0,
              
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {
          onChange(e) {
                this.file1=e.target.files[0];
                this.form.file=e.target.files;
                console.log(this.form.file)
                document.getElementById('filelabel').innerText=e.target.files[0].name;
            },
          setfields(e){
              if(e.target.value=='Website Update'){
                   var options = [
                     {id:1, name:'Blog Upload'},
                     {id:2, name:'Text Change'},
                     {id:3, name:'Page Added'},
                     {id:4, name:'Image Added'},
                     {id:5, name:'SEO Query'},
                     {id:6, name:'Issue Detected'},
                     
                 ]
                 this.options=options
                 this.set=1;
              

              }else if(e.target.value=='Graphic Design'){
                   var options = [
                     {id:1, name:'Flyer'},
                     {id:2, name:'Poster'},
                     {id:3, name:'Facebook Post'},
                     {id:4, name:'Facebook Cover'},
                     {id:5, name:'Business Card'},
                     {id:6, name:'Brochure'},
                     {id:7, name:'Logo'},
                     {id:8, name:'Header Paper'},
                     {id:9, name:'Banner'},
                     {id:10, name:'Other'},
                     
                 ]
                 this.options=options
                   this.set=2;

              }else if(e.target.value=='Social Media'){
                     var options = [
                     {id:1, name:'Event Created'},
                     {id:2, name:'Facebook Ah Hoc Post'},
                     {id:3, name:'Facebook Paid Advert'},
                     
                 ]
                 this.options=options
                 this.set=3;
              }else{
                  this.set=0;
              }

            },
             onChange2(e) {
                this.file2=e.target.files[0];
                document.getElementById('filelabel2').innerText=e.target.files[0].name;
            },
             onChange3(e) {
                this.file3=e.target.files[0];
                document.getElementById('filelabel3').innerText=e.target.files[0].name;
            },
             onChange4(e) {
                this.file4=e.target.files[0];
                document.getElementById('filelabel4').innerText=e.target.files[0].name;
            },
             onChange5(e) {
                this.file5=e.target.files[0];
                document.getElementById('filelabel5').innerText=e.target.files[0].name;
            },

          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/product?page=' + page).then(({ data }) => (this.connections = data.data));

              this.$Progress.finish();
          },
          createbase64image(fileObject){
            const reader = new FileReader();
            reader.onload = (e) => {
             // this.form.file = e.target.result
            };
            reader.readAsDataURL(fileObject);
          },
          loadConnections(){
              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
            const useremail = urlParams.get('useremail');
                const locationid = urlParams.get('locationid');

            // if(this.$gate.isAdmin()){
              axios.get(`/api/connections?email=${useremail}&locationid=${locationid}`).then(({ data }) => (this.connections = data.data))
               .then((data) => {
              setTimeout(() => {
                $("#datatable").DataTable({
                  lengthMenu: [
                    [5,10, 25, 50, -1],
                    [5,10, 25, 50, "All"],
                  ],
                  pageLength: 10,
                });
              });
            });
            // }
          },
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
          loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id };
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createConnection(){
        
            
              this.$Progress.start();

              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
              this.form.username = urlParams.get('username');
              this.form.file=this.file1;
         
              
              this.form.useremail = urlParams.get('useremail');
              this.form.locationid = urlParams.get('locationid');
               this.form.locationname = urlParams.get('locationname');
            var bodyFormData = new FormData();
            bodyFormData.append('type', this.form.type);
            bodyFormData.append('description', this.form.description);
            bodyFormData.append('text', this.form.text);
            bodyFormData.append('subtype', this.form.subtype);
            bodyFormData.append('size', this.form.size);
            bodyFormData.append('page', this.form.page);
            bodyFormData.append('username', this.form.username);
            bodyFormData.append('useremail', this.form.useremail);
            bodyFormData.append('locationid', this.form.locationid);
            bodyFormData.append('locationname', this.form.locationname);
            bodyFormData.append('title', this.form.title);
            bodyFormData.append('file1', this.file1); 
            bodyFormData.append('file2', this.file2); 
            bodyFormData.append('file3', this.file3); 
            bodyFormData.append('file4', this.file4); 
            bodyFormData.append('file5', this.file5); 
            bodyFormData.append('piclink',this.form.piclink);

        //  type : '',
        //             description : '',
        //             text: '',
        //             username:'',
        //             useremail:'',
        //             locationid:'',
        //             locationname:'',
        //             title: '',
        
            //     axios.post('/api/addconnection', {
            //   file: this.form.file,
            // })
            // .then(function (response) {
            //   console.log(response);
            // })
            // .catch(function (error) {
            //   console.log(error);
            // });
                        axios({
              method: "post",
              url: "/api/addconnection",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then(function (data) {
                console.log(data.data.success);
                if(data.data.success){
                  $('#addNew').modal('hide');
                   window.location.reload();
                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadConnections();
                 

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
              // this.form.post('/api/addconnection',
            
              // )
              // .then((data)=>{
              //   console.log(data.data.success);
              //   if(data.data.success){
              //     $('#addNew').modal('hide');

              //     Toast.fire({
              //           icon: 'success',
              //           title: data.data.message
              //       });
              //     this.$Progress.finish();
              //     this.loadConnections();

              //   } else {
              //     Toast.fire({
              //         icon: 'error',
              //         title: 'Some error occured! Please try again'
              //     });

              //     this.$Progress.failed();
              //   }
              // })
              // .catch(()=>{

              //     Toast.fire({
              //         icon: 'error',
              //         title: 'Some error occured! Please try again'
              //     });
              // })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          
          datetime(mydate){
       return moment(mydate).format('MMMM Do YYYY, h:mm:ss a'); 

          },
          deleteConnection(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/connection/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadConnections();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.useremail = urlParams.get('useremail');
            this.username= urlParams.get('username');
            this.form.username=this.$route.params.username;
            this.form.useremail=this.$route.params.useremail;
            this.form.locationname=this.$route.params.locationname;
            this.form.locationid=this.$route.params.locationid;
            

          // $(function () {
          //   $("#example1").DataTable({
          //     "responsive": true, "lengthChange": false, "autoWidth": false,
          //     "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          //   })
     
          // });



        },
        created() {
            this.$Progress.start();

            this.loadConnections();
            //this.loadCategories();
           // this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          }
        },
    }
</script>

<style scoped>
.image{
  width: 300px;
  height: 250px;
}
.circle{
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 5px;
}
.lb{
  background-color: lightskyblue;
}
.lo{
  background-color: orange;
}
.lr{
  background-color: lightcoral;
}
.br{
  background-color: red;
}
.green{
  background-color: lightgreen;
}
.blue{
  background-color: lightskyblue;
}
</style>
