<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <!-- left column -->
          <div class="col-md-12">
            <!-- general form elements -->
            
            <!-- /.card -->

            <!-- general form elements -->
            
            <!-- /.card -->

            <!-- Input addon -->
            <div class="card card-warning card-outline">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-edit"></i>
                  {{request.title}}
                </h3>
              </div>
              <div class="card-body">
                 <button type="button" class="btn btn-success toastrDefaultSuccess" @click="newModal">
                  Change status
                </button>
                 <button type="button" class="btn btn-success toastrDefaultSuccess" @click="assignusermodal">
                  Assign User
                </button>
                <button type="button" class="btn btn-success toastrDefaultSuccess" @click="assignprioritymodal">
                  Priority Settings
                </button>
                  <button type="button" class="btn btn-sm btn-primary" onClick="window.location.reload();">
                      <i class="fa fa-minus-square"></i>
                      Refresh
                  </button>
                  <button type="button" class="btn btn-sm btn-secondary" onclick="history.back()">
                      <i class="fa fa-minus-square"></i>
                      Back
                  </button>
                <div class="text-muted mt-3">
                 <b><i> Cat :</i></b> {{request.type}}  | <b><i> Type : </i></b>{{request.subtype}}
                </div>
                 <div class="text-muted mt-3">
                <b><i> Size:</i></b> {{request.size}}
                </div>
                <div class="text-muted mt-3">
                 <b><i>Page Url:</i></b> {{request.page}}
                </div>
                 <div class="text-muted mt-3">
                 {{request.description}}
                </div>
                <div class="text-muted mt-3">
                <b><i> Extra pics Links:</i></b> {{request.piclink}}
                </div>
                <div class="text-muted mt-3">
                    <p> <b><i>What you want done Exactly</i></b></p>
                 {{request.text}}
                </div>
                 <div class="text-muted mt-3">
                <a v-if="request.image" :href="'/Pictures/'+request.image" download>File1</a>
                <a v-if="request.file2" :href="'/Pictures/'+request.file2" download>|File2</a>
                <a v-if="request.file3" :href="'/Pictures/'+request.file3" download>|File3</a>
                <a v-if="request.file4" :href="'/Pictures/'+request.file4" download>|File4</a>
                <a v-if="request.file5" :href="'/Pictures/'+request.file5" download>|File5</a>
                </div>
                <div class="text-muted mt-3">
                    <p><b><i> Date :{{datetime(request.date)}}</i></b> </p>
                 
                </div>

                <hr>
          
                <form @submit.prevent="submitcomment()" >
               <div class="form-group">
                        <label>Add a comment </label>
                        <textarea v-model="form.content" class="form-control" rows="3" placeholder="Enter ..."></textarea>
               </div>

               <button type="submit" class="btn btn-info btn-lg" style="color:white;">Comment</button><br>

             
            

                </form>
                <hr>
                  <div class="row">
                    <div class="timeline timeline-inverse">
                      <!-- timeline time label -->
                     
                      <!-- /.timeline-label -->
                      <!-- timeline item -->
                      <div v-for="c in comments" :key="c.id">
                        <i class="fas fa-envelope bg-success" v-if="c.type==1"></i>
                          <i class="fas fa-envelope bg-primary" v-if="c.type!=1"></i>

                        <div class="timeline-item">
                          <span class="time"><i class="far fa-clock"></i> {{datetime(c.created_at)}}</span>

                          <h3 class="timeline-header"><a href="#">{{request.username}}</a> {{request.useremail}}</h3>

                          <div class="timeline-body">
                            {{c.content}}
                          </div>
                          <div class="timeline-footer">
                            <p>Date :{{datetime(c.created_at)}} </p>
                          </div>
                        </div>
                      </div>

                       <div v-if="request.status=='Request Complete'" class="col-md-12">
                        <i class="fas fa-envelope bg-success"></i>

                        <div class="timeline-item">
                          <span class="time"><i class="far fa-clock"></i> {{datetime(request.created_at)}}</span>

                          <h3 class="timeline-header alert alert-success" style="white-space: nowrap;">REQUEST - <b class="">COMPLETED</b> </h3>

                          <div class="timeline-body">
                            {{request.complete_text}}
                          </div>
                          <div class="timeline-body">
                           <a :href=request.complete_link>{{request.complete_link}}</a> 
                          </div>
                          <div class="timeline-footer">
                            <p>Date :{{datetime(request.created_at)}} </p>
                          </div>
                        </div>
                      </div>

                        <div v-if="request.status=='Request Requires Further Information'">
                        <i class="fas fa-envelope bg-warning"></i>

                        <div class="timeline-item">
                          <span class="time"><i class="far fa-clock"></i> {{datetime(request.created_at)}}</span>

                          <h3 class="timeline-header alert alert-warning">UPDATE - <b> MORE INFO NEEDED</b> </h3>

                          <div class="timeline-body">
                            {{request.complete_text}}
                          </div>
                          <div class="timeline-footer">
                            <p>Date :{{datetime(request.created_at)}} </p>
                          </div>
                        </div>
                      </div>
 

                    
                      <div>
                        <i class="far fa-clock bg-gray"></i>
                      </div>
                    </div>

                    
                
                  </div>
            
              </div>
              <!-- /.card -->
            </div>
            <!-- /.card -->
            <!-- Horizontal Form -->
            
            <!-- /.card -->

          </div>
          <!--/.col (left) -->
          <!-- right column -->
          
          <!--/.col (right) -->
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Change Status</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Product</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateProduct() : createConnection()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="form-group">  
                          <label for="exampleSelectRounded0">Status</label>
                          <select v-model="form.status" class="custom-select rounded-0" id="exampleSelectRounded0" @change="settype">
                            <option>Request Submitted</option>
                            <option>Request In Progress</option>
                            <option>Request Complete</option>
                            <option>Request Requires Further Information</option>
                          </select>
                
                            <has-error :form="form" field="name"></has-error>
                        </div>
                   
                        <div class="form-group" v-if="checktype>1">
                            
                          <label>CONTENT</label>
                          <textarea class="form-control" v-model="form.content" rows="3" placeholder="More details"></textarea>
                        
                        </div>
                   
                          <div class="form-group" v-if="checktype>2">
                            <label>Link  </label>
                            <input v-model="form.link" type="text" name="price"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                        </div>
                   
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">CHANGE STATUS</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>








  <div class="modal fade" id="assign" tabindex="-1" role="dialog" aria-labelledby="assign" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Assign User</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Product</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateProduct() : assignuser()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="form-group">  
                          <label for="exampleSelectRounded0">Status</label>
                          <select v-model="form1.user" class="custom-select rounded-0" id="exampleSelectRounded0" @change="settype">
                            <option value="support@smedigi.io_Support">Support</option>
                            <!-- <option value="Natacha@childcaremarketing.co.uk_Social Team">Social Media Team</option>
                            <option value="Jess@childcaremarketing.co.uk_Website Team">Website Team</option>
                            <option value="Ben@childcaremarketing.co.uk_Marketing Team">Marketing Team</option> -->
                          </select>
                
                        </div>
                   
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">ASSIGN USER</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>











  <div class="modal fade" id="assignpriority" tabindex="-1" role="dialog" aria-labelledby="assignprority" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Prority</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Product</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="assignpriority()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="form-group">  
                          <label for="exampleSelectRounded0">Priority</label>
                          <select v-model="form2.priority" class="custom-select rounded-0" id="exampleSelectRounded0">
                            <option>Low</option>
                            <option>Medium</option>
                            <option>High</option>
                            <option>Urgent</option>
                          </select>
                
                        </div>
                   
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">SET PRIORITY</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>















    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import moment from "moment"

    export default {
      components: {
          VueTagsInput,
          moment
        },
        data () {
            return {
                editmode: false,
              //  url_data:'',
           //     file:'',
                checktype:1,
                request : {},
                form: new Form({
                    status : '',
                    content : '',
                    link:'',
                }),
                form1: new Form({
                    user : '',
                }),
                form2: new Form({
                    priority:'',
                }),
                comments: [],
              
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {
          onChange(e) {
           // console.log(url_data)
                this.createbase64image(e.target.files[0])
               // console.log(e.target.files[0]);
                document.getElementById('filelabel').innerText=e.target.files[0].name;
            },

            settype(){
             console.log(this.form.status)
             if(this.form.status=='Request In Progress' || this.form.status=='Request Submitted'){
                 this.checktype=1;
             }else if(this.form.status=='Request Requires Further Information'){
                 this.checktype=2;
             }else{
                 this.checktype=3;
             }

            },
            assignusermodal(){
              this.editmode = false;
              this.form.reset();
              $('#assign').modal('show');
            },
              assignprioritymodal(){
              this.editmode = false;
              this.form2.reset();
              $('#assignpriority').modal('show');
            },
            assignpriority(){
                 this.form2.post(`/api/assignpriority?id=${this.$route.params.id}`
              )
              .then((data)=>{
                console.log(data.data.success);
                if(data.data.success){
                  $('#assignpriority').modal('hide');
                  this.form2.user='';

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.initiate();
                  this.$Progress.finish();
                 // this.loadConnections();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
            },

            assignuser(){
            
              this.form1.post(`/api/assignuser?id=${this.$route.params.id}`
              )
              .then((data)=>{
                console.log(data.data.success);
                if(data.data.success){
                  $('#assign').modal('hide');
                  this.form1.user='';

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.initiate();
                  this.$Progress.finish();
                 // this.loadConnections();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
            },
          loadConnections(){
              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
            const useremail = urlParams.get('useremail');

            // if(this.$gate.isAdmin()){
              axios.get(`/api/connections?email=${useremail}`).then(({ data }) => (this.connections = data.data));
            // }
          },
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
       
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createConnection(){
              this.$Progress.start();
            //   const queryString = window.location.search;
            //   const urlParams = new URLSearchParams(queryString);
            //   this.form.username = urlParams.get('username');
            //   this.form.useremail = urlParams.get('useremail');
            //   this.form.locationid = urlParams.get('locationid');
            //    this.form.locationname = urlParams.get('locationname');

              this.form.post(`/api/changestatus?id=${this.$route.params.id}`
              )
              .then((data)=>{
                console.log(data.data.success);
                if(data.data.success){
                  $('#addNew').modal('hide');
                  this.form.content='';

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.initiate();
                  this.$Progress.finish();
                 // this.loadConnections();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          
          datetime(mydate){
       return moment(mydate).format('MMMM Do YYYY, h:mm:ss a'); 

          },
          assignNew(){

          },
          submitcomment(){
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const username = urlParams.get('username');
              this.$Progress.start();
              this.form.post(`/api/comments/add?id=${this.url_data}&origin=${username}&type=1`)
              .then((response) => {
                  this.comments=response.data;
                
                
                  this.$Progress.finish();
                  this.form.content='';
                  
              })
              .catch(() => {
                  this.$Progress.fail();
              });


          },
          initiate(){
            this.url_data=this.$route.params.id;
            axios.get(`/api/singlerequest?id=${this.url_data}`).then(({ data }) => {this.request = data
            console.log(this.request)
            });
            this.$Progress.start();
              axios.get(`/api/getcomments?id=${this.$route.params.id}`)
              .then((response) => {
                  this.comments=response.data;
                
                
                  this.$Progress.finish();
                  
              })
              .catch(() => {
                  this.$Progress.fail();
              });
          },
          deleteConnection(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/connection/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadConnections();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
          this.initiate()
            var ids= this.$route.params.id
         
              axios.get(`/api/getcomments?id=${ids}`)
              .then((response) => {this.comments=response.data})
  
          
        },
        created() {
            this.$Progress.start();

            this.loadConnections();
            //this.loadCategories();
           // this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          }
        },
    }
</script>

<style scoped>
.image{
  width: 300px;
  height: 250px;
}
</style>
